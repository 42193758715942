import { Box } from '@chakra-ui/react';
import React, { Suspense, lazy } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const MainComponent = lazy(() => import('./Main'));

const snipper = <Box display='flex' w='100vw' h='100vh' justifyContent='center' alignItems='center'><ClipLoader color="#ff0000" /></Box>

function App() {
  return (
    <Suspense fallback={snipper}>
      <MainComponent />
    </Suspense>
  );
}

export default App;
